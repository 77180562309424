import React from "react";
import Nav from "./nav/nav.jsx";
import TopLine from "./top_line/top_line.jsx";
import ButtonMore from "./button_more/button_more.jsx";
import Logotype from "./logo/logo.jsx";
import "./header.css";

function Header() {
  return (
    <>
      <TopLine />
      <header className="top_header">
        <div className="wrapper_nav">
          <Logotype />

          <Nav />

          <ButtonMore linkText="хочу разбор" />
        </div>
      </header>
    </>
  );
}

export default Header;
