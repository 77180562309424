import React, { lazy } from "react";
import "./main_page.css";

import about_me_img from "./img/about_me.webp";
import { Carousel } from "../../components/carousel/carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Helmet from "react-helmet";

const PlateThumb = lazy(() =>
  import("../../components/plate_thumb/plate_thumb")
);
const BlockSpecial = lazy(() =>
  import("../../components/block_special/block_scecial")
);

export const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>АстроАнна</title>
        <meta name="description" content="Астрология от АстроАнн" />
        <meta name="keywords" content="астрология" />
      </Helmet>

      <section>
        <div className="first_block">
          <div className="wrapper" data-aos="fade-up" data-aos-duration="1000">
            <div className="heading_sub">AstroAnn</div>
            <h1>
              Детская Семейная Астрология отношения
              <br />и профориентация ребёнка.
            </h1>
            <p>
              Помогу родителям лучше понять Себя, своего ребенка: его
              потребности, мотивации, черты характера, а на консультации
              профориентация помогу подобрать подходящие сферы деятельности и
              специальности для самореализации.
            </p>

            <h3>помогу понять :</h3>
            <Carousel />
          </div>
        </div>
        <div className="index_about_me">
          <div className="wrapper">
            <div className="heading_sub">Обо мне</div>
            <div className="about_me">
              <div
                className="wrapper_left"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2>
                  Я занимаюсь изучением влияния планет <br />
                  на характер и судьбу человека
                </h2>
                <p>
                  Являюсь Сертифицированным астролог школы классической западной
                  астрологии. Моя работа основана на анализе гороскопов и
                  предсказании событий, используя знания астрономии и символики
                  зодиака.
                </p>
                <ul className="about_me__ul">
                  <li>
                    <p>
                      Наблюдая ребенка, я могу увидеть его потенциал и помочь
                      ему раскрыть свои таланты
                      <br />и способности.
                    </p>
                  </li>
                  <li>
                    <p>
                      Астрология помогает мне понять индивидуальные особенности
                      ребенка и подобрать подходящие методики обучения.
                    </p>
                  </li>
                  <li>
                    <p>
                      Я учитываю психологические и эмоциональные особенности
                      ребенка, чтобы помочь <br />
                      ему преодолеть трудности и развиться гармонично.
                    </p>
                  </li>
                  <li>
                    <p>
                      С помощью астрологии я создаю индивидуальную программу
                      развития для каждого
                      <br />
                      ребенка, учитывая его уникальные черты личности.
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className="wrapper_right"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <LazyLoadImage effect="blur" src={about_me_img} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="service">
          <div
            className="wrapper"
            data-aos="fade-down"
            data-aos-duration="1000"
          >
            <div className="heading_sub__service">Услуги</div>
            <h2>Помощь в понимании характера и потенциала вашего ребенка</h2>
            <p>
              Aстролог объяснит вам как раскрыть потенциал вашего ребенка,
              разгадать его уникальные черты характера
              <br />и поможет в воспитании, опираясь на звезды.
            </p>
            <Carousel />
          </div>
        </div>
        <div className="plug"></div>
        <PlateThumb headingSub="гороскоп" />
        <BlockSpecial />
      </section>
    </>
  );
};
