import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./button_more.css";
import { ModuleWindow } from "../../modal_window/modal_window.jsx";

export default function Button_more(props) {
  const [modalActive, setModalActive] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_cnv2ui8", "template_oi5hphp", form.current, {
        publicKey: "Wo1eGL6pG0jO4gcAk",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const submitButtonClose = () => {
    setTimeout(() => {
      setModalActive(false);
    }, 1000);
  };

  return (
    <>
      <div className="top__header__more">
        <button onClick={() => setModalActive(true)}>{props.linkText}</button>
      </div>
      <ModuleWindow active={modalActive} setActive={setModalActive}>
        <p className="modalWindowHeader">
          Заполните формы и получите первую консультацию <b>со скидкой 20%</b>!
        </p>
        <form ref={form} onSubmit={sendEmail} id="formPopUp">
          <label htmlFor="user_name">Имя*:</label>
          <input
            type="text"
            name="user_name"
            id="user_name"
            placeholder="Ваше имя"
            required
          />
          <label htmlFor="user_phone">Телефон*:</label>
          <input type="phone" name="user_phone" id="user_phone" required />
          <label htmlFor="user_date">Дата рождения*:</label>
          <input type="date" name="user_date" required id="user_date" />
          <label htmlFor="user_message">Сообщение:</label>
          <textarea
            name="message"
            typeof="text"
            placeholder="Место вашего рождения..."
            required
            id="user_message"
          />

          <input type="submit" value="отправить" onClick={submitButtonClose} />
        </form>
      </ModuleWindow>
    </>
  );
}
