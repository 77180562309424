import React from "react";
import TelegramImg from "./img/telegram.png";
import WhatsAppImg from "./img/whatsapp.png";
import ViberImg from "./img/viber.png";
import "./top_line.css";

export default function Top_line() {
  return (
    <div className="header_contact">
      <div className="wrapper_concat">
        <div className="header_socio">
          <a
            href="https://msng.link/o?79370717404=vi?text=Здравствуйте"
            target="_blank"
          >
            <img src={ViberImg} alt="" />
            Viber
          </a>
          <a href="https://wa.me/79370717404?text=Здравствуйте" target="_blank">
            <img src={WhatsAppImg} alt="" />
            WhatsApp
          </a>
          <a href="">
            <img src={TelegramImg} alt="" />
            Telegram
          </a>
        </div>
        <div className="header_phone">
          <a href="tel:+79370717404">+7(937)0717404</a>
        </div>
      </div>
    </div>
  );
}
