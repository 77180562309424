import React from "react";
import Logo from "./logo.png";
import "./logo.css";
import { Link } from "react-router-dom";

export default function Logotype() {
  return (
    <div className="top_header_logo">
      <Link to="/">
        <img src={Logo} alt="AstroAnna logotype" />
      </Link>
    </div>
  );
}
