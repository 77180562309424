import React from "react";
import "./nav.css";
import { MenuBurger } from "../../menu_burger/menu_burger";
import { NavLink } from "react-router-dom";

function Nav() {
  return (
    <>
      <MenuBurger />
      <nav className="top_header_nav">
        <ul>
          <li>
            <NavLink to="/">главная</NavLink>
          </li>
          <li>
            <NavLink to="/astrology">астрология</NavLink>
          </li>
          <li>
            <NavLink to="/horoscope">гороскопы</NavLink>
          </li>
          <li>
            <NavLink to="/consultation">консультации</NavLink>
          </li>
          <li>
            <NavLink to="/about-me">обо мне</NavLink>
          </li>
          <li>
            <NavLink to="contacts">контакты</NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Nav;
