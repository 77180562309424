import React, { Suspense, lazy } from "react";
import Header from "./components/header/header";
import { Footer } from "./components/footer/footer";
import { Route, Routes, Outlet } from "react-router-dom";
import { MainPage } from "./page/main_page/main_page.jsx";
import { BackToTop } from "./components/BacktoTop/BackToTop.jsx";

const NotFound = lazy(() => import("./page/404/404.jsx"));
const ContactsPage = lazy(() => import("./page/page_contacts/contacts.jsx"));
const AstrologyPage = lazy(() =>
  import("./page/page_astrology/page_astrology")
);
const ConsultationPage = lazy(() =>
  import("./page/page_consultation/page_consultation.jsx")
);
const AboutMePage = lazy(() => import("./page/about_me/about_me.jsx"));
const HoroscopePage = lazy(() =>
  import("./page/page_horoscope/page_horoscope.jsx")
);
function App() {
  return (
    <>
      <Header />
      <Suspense fallback={<h2 className="onLoadPage">Загрузка...</h2>}>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/astrology" element={<AstrologyPage />}></Route>
          <Route path="/horoscope" element={<HoroscopePage />}></Route>
          <Route path="/consultation" element={<ConsultationPage />}></Route>
          <Route path="/about-me" element={<AboutMePage />}></Route>
          <Route path="/contacts" element={<ContactsPage />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Outlet />
        <BackToTop />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
