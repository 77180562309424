import React, { lazy } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./carousel.css";

import thumb_1 from "./img/thumb-1.webp";
import thumb_2 from "./img/thumb-2.webp";
import thumb_3 from "./img/thumb-3.webp";
import thumb_4 from "./img/thumb-4.webp";

export const Carousel = () => {
  return (
    <>
      <div className="carousel" data-aos="flip-up" data-aos-duration="1300">
        <article className="carousel__article">
          <Link to="/consultation#1" aria-label="Анализ астрологической карты ребенка">
            <LazyLoadImage
              effect="blur"
              src={thumb_1}
              alt="Анализ астрологической карты ребенка"
            />
          </Link>
          <div className="carousel__caption">
            <div className="carousel__ enrty_caption">
              <h5>Анализ карты ребенка</h5>
            </div>
          </div>
        </article>
        <article className="carousel__article">
          <Link to="/consultation#5" aria-label="Раскрыть таланты ребенка">
            <LazyLoadImage
              effect="blur"
              src={thumb_2}
              alt="Раскрыть таланты ребенка астрология"
            />
          </Link>
          <div className="carousel__caption">
            <div className="carousel__ enrty_caption">
              <h5>Раскрыть таланты ребенка</h5>
            </div>
          </div>
        </article>
        <article className="carousel__article">
          <Link to="/consultation#3" aria-label="Подобрать профессию ребенка астрология">
            <LazyLoadImage
              effect="blur"
              src={thumb_3}
              alt="Подобрать профессию ребенка астрология"
            />
          </Link>
          <div className="carousel__caption">
            <div className="carousel__ enrty_caption">
              <h5>Подобрать профессию</h5>
            </div>
          </div>
        </article>
        <article className="carousel__article">
          <Link to="/consultation#2" aria-label="Астрология жить в гармонии с собой">
            <LazyLoadImage
              effect="blur"
              src={thumb_4}
              alt="Астрология жить в гармонии с собой"
            />
          </Link>
          <div className="carousel__caption">
            <div className="carousel__ enrty_caption">
              <h5>Жить в гармонии с собой</h5>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};
