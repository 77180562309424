import { NavLink } from "react-router-dom";
import { React, useState } from "react";
import Button_more from "../header/button_more/button_more";

import "./menu_burger.css";

export const MenuBurger = () => {
  const [state, setState] = useState(false);
  const onClick = () => setState(!state);

  return (
    <>
      <div className="mobile-nav">
        <div onClick={onClick} className="burger-ico"></div>

        <nav className={`mobile_top_header_nav ${state ? "open" : ""}`}>
          <div onClick={onClick} className="burger-ico-close"></div>
          <ul>
            <li>
              <NavLink to="/" onClick={onClick}>
                главная
              </NavLink>
            </li>
            <li>
              <NavLink to="/astrology" onClick={onClick}>
                астрология
              </NavLink>
            </li>
            <li>
              <NavLink to="/horoscope" onClick={onClick}>
                гороскопы
              </NavLink>
            </li>
            <li>
              <NavLink to="/consultation" onClick={onClick}>
                консультация
              </NavLink>
            </li>
            <li>
              <NavLink to="/about-me" onClick={onClick}>
                обо мне
              </NavLink>
            </li>
            <li>
              <NavLink to="contacts" onClick={onClick}>
                контакты
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};
